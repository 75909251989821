<template>
  <div>
    <v-skeleton-loader class="mx-auto ml-2 mr-2" type="card" :loading="loading">
      <!-- <v-container fluid class="mb-4"> -->
      <CommunityInfo
        :communityInfo="communityInfo"
        @onRelatedCommunityChanged="showRelatedCommunity"
      />
      <InternalBreadcrumbs :breadcrumbs="breadcrumbs" />
      <v-card
        elevation="0"
        outlined
        color="#f1f1f1"
        :loading="serviceRequestProcessing"
      >
        <template slot="progress">
          <v-progress-linear height="10" indeterminate></v-progress-linear>
          <p class="mt-4 text-center">Processing...</p>
        </template>
        <!-- <v-card-title v-if="!serviceRequestProcessing"
          >New Service Request</v-card-title
        > -->
        <v-card-text v-if="!serviceRequestProcessing">
          <!-- Start New Service Request section -->
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="newRequest.communityName"
                    label="* Community"
                    readonly
                    required
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <v-radio-group
                    v-model="requestType"
                    label="* Choose Type (Only one service type may be selected)"
                  >
                    <v-row>
                      <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        lg="3"
                        v-for="(requestTypeItem, i) in requestTypeItems"
                        :key="'request_type_col_' + i"
                      >
                        <v-radio
                          :value="requestTypeItem.id"
                          :label="requestTypeItem.name"
                        ></v-radio>
                      </v-col>
                    </v-row>
                  </v-radio-group>
                  <v-checkbox
                    v-model="newRequest.isInternal"
                    label="Internal only"
                  >
                  </v-checkbox>
                </v-col>
              </v-row>
              <!-- <v-row>
                <v-col cols="12" md="6">
                  <v-select
                    :loading="loadingServiceRequestTypes"
                    v-model="requestType"
                    :items="requestTypeItems"
                    item-text="name"
                    item-value="id"
                    label="* Choose type..."
                    :rules="[v => !!v || 'Type is required']"
                    required
                  >
                  </v-select>
                  <v-checkbox
                    v-model="newRequest.isInternal"
                    label="Internal only"
                  >
                  </v-checkbox>
                </v-col>
              </v-row> -->
              <!-- <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="newRequest.title"
                    label="* Service Request Title"
                    required
                  ></v-text-field>
                </v-col>
              </v-row> -->
              <v-row>
                <v-col cols="12" md="6">
                  <v-textarea
                    v-model="newRequest.description"
                    label="* Describe Service Request (Only include a description for one service request per ticket)"
                    :rules="[v => !!v || 'Description is required']"
                    required
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-row>
                  <v-col cols="12" md="4">
                    <div
                      v-for="attachment in attachmentsObj"
                      cols="12"
                      class="darggable-col"
                      :key="attachment.id"
                    >
                      <MediaUploader
                        class="pb-5"
                        uploadType="photo"
                        :key="attachment.id"
                        :id="`multiFilePost_${attachment.id}`"
                        :index="attachment.id"
                        :accept="accept"
                        :multiple="true"
                        :extensions="extensions"
                        :sizeImage="10485760"
                        :sizeVideo="104857600"
                        :fileId.sync="attachment.fileId"
                        :file-url.sync="attachment.url"
                        :isUploading.sync="isFileUploading"
                        @onMultipleUploaded="onMultipleAddFile"
                        @onChange="onChange"
                        @onDeleteFile="onDeleteFile"
                      />
                    </div>
                  </v-col>
                </v-row>
              </v-row>
              <!-- <v-row>
                <v-col cols="12" md="4">
                  <v-select
                    :loading="loadingPriorityItems"
                    v-model="priority"
                    :items="priorityItems"
                    item-text="name"
                    item-value="id"
                    label="* Choose priority..."
                    :rules="[v => !!v || 'Priority is required']"
                    required
                  ></v-select>
                </v-col>
              </v-row> -->
              <v-row
                v-if="
                  permissions &&
                    permissions.serviceRequest &&
                    permissions.serviceRequest.useOnBehalfOf
                "
              >
                <v-col cols="12" md="6">
                  <v-select
                    :loading="loadingCommunityUsers"
                    v-model="onBehalfOf"
                    :items="communityUsers"
                    item-text="fullName"
                    item-value="id"
                    label="On behalf of user (optional)..."
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-textarea
                    v-model="newRequest.location"
                    label="* Please indicate the residence address, address in close proximity or the location in the community (i.e. entrance, pool area, end of Whitehosrse dr., etc.) relating to this service request:"
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="6">
                  <v-autocomplete
                    :loading="loadingYnTeamItems"
                    v-model="assignedTo"
                    :items="ynTeamItems"
                    item-text="fullName"
                    item-value="id"
                    label="* Assigned to"
                    :rules="[v => !!v || 'Assigned To is required']"
                    required
                    clearable
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="4">
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="formattedCompletionDate"
                        label="* Target Completion Date"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        @blur="
                          completionDate = parseDate(formattedCompletionDate)
                        "
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="completionDate"
                      no-title
                      @input="menu1 = false"
                      :rules="[
                        v => !!v || 'Target Completion Date is required'
                      ]"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="newRequest.contactName"
                    label="* Contact Name"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <!-- <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="newRequest.contactEmail"
                    label="Contact Email"
                    :required="isNeighborhoodRequest"
                  ></v-text-field>
                </v-col>
              </v-row> -->
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="newRequest.contactPhone"
                    label="* Contact Phone"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <p style="font-size: larger; font-weight: bold;">
                    * Indicates required fields
                  </p>
                </v-col>
              </v-row>
              <v-btn class="orange-bg white--text" @click="saveServiceRequest"
                >Create New Service Request</v-btn
              >
            </v-container>
          </v-form>

          <!-- End New Service Request section -->
        </v-card-text>
      </v-card>

      <!-- </v-container> -->
    </v-skeleton-loader>
  </div>
</template>

<script>
import CommunityInfo from "@/view/components/CommunityInfo.vue";
import InternalBreadcrumbs from "@/view/components/DashboardInternalBreadcrumbs.vue";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import {
  GET_QUERY,
  POST_DATA,
  API_CUSTOMERS,
  // API_EMPLOYEES,
  API_SERVICE_REQUESTS,
  API_SERVICE_REQUEST_TYPES
  // API_SERVICE_REQUEST_PRIORITIES
} from "@/core/store/crud.module";
import crud from "@/core/helpers/crud";
import dateFormatter from "@/core/helpers/dateFormatter";
import stringFormatter from "@/core/helpers/stringFormatter";
import { validationMixin } from "vuelidate";
// import dataModels from "@/core/helpers/datamodels.js";
// import { required } from "vuelidate/lib/validators";
import MediaUploader from "@/view/components/imageUploader/mediaUploader.vue";
import Mgr from "@/core/services/security.service";
import moment from "moment";
import permissionsHelper from "@/core/helpers/permissionsHelper";

export default {
  mixins: [validationMixin],
  components: {
    CommunityInfo,
    InternalBreadcrumbs,
    MediaUploader
  },
  data: () => ({
    mgr: new Mgr(),
    userInfo: null,
    communityInfo: {},
    newRequest: {
      communityName: "",
      title: "",
      requestType: "",
      description: "",
      isInternal: false,
      attachments: [],
      // priority: "",
      assignedTo: "",
      targetCompletionDate: null,
      location: ""
    },
    serviceRequestProcessing: false,
    menu1: false,
    completionDate: null,
    formattedCompletionDate: null,
    requestType: "",
    requestTypeItems: [],

    ynTeamItems: [],

    // priority: "",
    // priorityItems: [
    //   { name: "Low", value: "Low" },
    //   { name: "Normal", value: "Normal" },
    //   { name: "High", value: "High" }
    // ],
    assignedTo: null,

    options: {
      page: 1,
      itemsPerPage: 5
    },
    pageText: "",
    startingAfter: "",
    endingBefore: "",
    previousPage: 1,
    hasMore: false,

    loading: false,
    loadingServiceRequestTypes: false,
    // loadingPriorityItems: false,
    loadingYnTeamItems: false,
    snackbarContent: "",
    snackbarColor: "#F06835",
    breadcrumbs: [],
    attachmentsObj: [{ id: 1, url: null }],
    isFileUploading: false,
    videoExtensions: "mp4,mov,avi,mkv,mpg,vob,wmv,m4v,asf,srt",
    loadingCommunityUsers: false,
    onBehalfOf: null,
    communityUsers: [],
    permissions: {}
  }),
  props: {
    customerId: {
      type: [Number, String],
      default: 0
    }
  },
  validations() {
    return true;
    // return {
    //   billingInfo: {
    //     subscription: {
    //       nextBillingDates: {
    //         required,
    //         minDate: value =>
    //           dateFormatter.convertToMilliseconds(value) >
    //           dateFormatter.convertToMilliseconds(new Date())
    //       }
    //     }
    //   }
    // };
  },
  watch: {
    completionDate: function() {
      this.formattedCompletionDate = this.formatDate(this.completionDate);
    },
    onBehalfOf: function() {
      if (
        this.communityUsers.filter(el => el.id === this.onBehalfOf).length > 0
      )
        this.newRequest.location = this.communityUsers.filter(
          el => el.id === this.onBehalfOf
        )[0].address;
    }
  },
  async mounted() {
    await this.getEmployees();
  },
  async created() {
    let self = this;
    self.loading = true;

    await self.getComunityInfo();

    permissionsHelper.getPermissions().then(self.getPermissions);

    self.userInfo = await self.mgr.getUser(); //.then(userInfo => (self.userInfo = userInfo));

    await self.getServiceRequestTypes();

    // set default completion date
    let dueDate = moment.utc(new Date()).add(3, "d");
    let weekDay = dueDate.day();
    let addDays = 0;
    if (weekDay == 6) addDays = 2;
    else if (weekDay == 0) addDays = 1;
    dueDate = dueDate.add(addDays, "d");
    self.completionDate = new Date(dueDate).toISOString().substr(0, 10); //moment.utc(dueDate);

    self.newRequest.location = self.userInfo?.profile?.location;
    self.newRequest.contactName = self.userInfo?.profile?.contactName;
    self.newRequest.contactPhone = self.userInfo?.profile?.contactPhone;
    self.newRequest.contactEmail = self.userInfo?.profile?.contactEmail;

    self.loading = false;
  },
  methods: {
    getPermissions(permissionsObj) {
      this.permissions = permissionsObj;
    },
    getAssignedTo(assignedTo) {
      return assignedTo?.fullName;
    },
    getCreatedBy(createdBy) {
      return createdBy?.fullName;
    },
    showRelatedCommunity(communityId) {
      this.$router.push({
        name: "CustomerDetails",
        params: { customerId: communityId }
      });
    },
    async getComunityInfo() {
      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_CUSTOMERS,
          id: this.customerId
        })
        .then(response => {
          if (response.status >= 200 && response.status <= 204) {
            this.communityInfo = crud.getPopulatedComunityInfo(response.data);

            this.ynTeamItems.push(this.communityInfo.accountManager);
            this.assignedTo = this.communityInfo.accountManager.id;

            this.createBreadcrumb();
            this.setInternalBreadcrumbs();

            this.newRequest.communityName = this.communityInfo.name;
            // this.getRecipients();
          } else {
            this.$snackbar.showMessage({
              content:
                response.data ||
                "Cannot receive Community details. Something went wrong!",
              color: "red"
            });
          }
        });
    },
    // async getServiceRequestPriorities() {
    //   this.loadingPriorityItems = true;

    //   await this.$store
    //     .dispatch(GET_QUERY, {
    //       listName: API_SERVICE_REQUEST_PRIORITIES
    //     })
    //     .then(response => {
    //       if (response.status >= 200 || response.status <= 204) {
    //         this.priorityItems = response.data.$values;
    //       } else {
    //         this.$snackbar.showMessage({
    //           content: response.data || "Something went wrong!",
    //           color: "red"
    //         });
    //       }
    //       this.loadingPriorityItems = false;
    //     });
    // },
    async getServiceRequestTypes() {
      this.loadingServiceRequestTypes = true;

      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_SERVICE_REQUEST_TYPES
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            this.requestTypeItems = response.data.$values;

            if (this.communityInfo.name.includes("Del Webb Grande Dunes")) {
              let allowedTypes = [
                "General Service",
                "Crew, storm or other damage",
                "Request For Proposal"
              ];

              let allowedTypeIds = [6, 11, 12];
              this.requestTypeItems = this.requestTypeItems.filter(
                el =>
                  allowedTypes.includes(el.name) ||
                  allowedTypeIds.includes(el.id)
              );
            } else if (
              this.communityInfo.name.includes("Del Webb at Lakewood Ranch") ||
              this.communityInfo.name.includes("Del Webb Lakewood Ranch Area")
            ) {
              let disallowedTypes = ["Wildlife Damage", "Mulch Request"];
              let disallowedTypeIds = [14, 15];
              this.requestTypeItems = this.requestTypeItems.filter(
                el =>
                  !disallowedTypes.includes(el.name) &&
                  !disallowedTypeIds.includes(el.id)
              );
            } else if (
              this.communityInfo.name.includes("Sun City Peachtree Homes")
            ) {
              let allowedTypes = [
                "General Service",
                "Irrigation",
                "Mulch Request",
                "Request for Proposal",
                "Plant Request",
                "Sod Request",
                "Crew, storm or other damage"
              ];
              let allowedTypeIds = [4, 6, 11, 12, 14, 16, 17];
              this.requestTypeItems = this.requestTypeItems.filter(
                el =>
                  allowedTypes.includes(el.name) ||
                  allowedTypeIds.includes(el.id)
              );
            }
          } else {
            this.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }
          this.loadingServiceRequestTypes = false;
        });
    },

    async saveServiceRequest() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.newRequest.communityId = this.communityInfo.aspireId;
      this.newRequest.isNew = true;
      this.newRequest.isOpen = false;
      this.newRequest.isInProcess = false;
      this.newRequest.isCompleted = false;
      this.newRequest.skipNotification =
        process.env.VUE_APP_SKIP_NOTIFICATIONS == "1";
      let requestTypeObj = this.requestTypeItems[0];
      if (
        this.requestTypeItems.filter(el => el.id === this.requestType).length >
        0
      )
        requestTypeObj = this.requestTypeItems.filter(
          el => el.id === this.requestType
        )[0];

      this.newRequest.requestType = {
        id: requestTypeObj.id,
        name: requestTypeObj.name
      };

      this.newRequest.title = this.newRequest.requestType.name;

      // we don't need priority now. set default value on server side
      // let priorityObj = this.priorityItems[0];
      // if (this.priorityItems.filter(el => el.id === this.priority).length > 0)
      //   priorityObj = this.priorityItems.filter(
      //     el => el.id === this.priority
      //   )[0];

      // this.newRequest.priority = {
      //   id: priorityObj.id,
      //   name: priorityObj.name
      // };

      let assignedToObj = this.ynTeamItems[0];
      if (this.ynTeamItems.filter(el => el.id === this.assignedTo).length > 0)
        assignedToObj = this.ynTeamItems.filter(
          el => el.id === this.assignedTo
        )[0];

      this.newRequest.assignedTo = {
        id: assignedToObj.id
      };

      if (this.onBehalfOf) {
        this.newRequest.createdBy = { id: this.onBehalfOf };
      }

      this.newRequest.targetCompletionDate = moment.utc(this.completionDate);

      this.newRequest.attachments = this.attachmentsObj
        .filter(i => i.url && i.url.length > 0)
        .map(i => {
          return { id: i.fileId, fileUrl: i.url };
        });
      this.newRequest.attachments = this.newRequest.attachments.filter(
        (item, index) => this.newRequest.attachments.indexOf(item) === index
      ); //remove duplicates

      if (
        !this.newRequest.assignedTo ||
        // !this.newRequest.title ||
        !this.newRequest.description ||
        // !this.newRequest.priority ||
        !this.newRequest.requestType ||
        !this.newRequest.contactName ||
        !this.newRequest.contactPhone ||
        !this.newRequest.targetCompletionDate
      ) {
        this.snackbarContent =
          "Please, populate all required fields (*) on the form";
        this.snackbarColor = "red";
        this.$snackbar.showMessage({
          content: this.snackbarContent,
          color: this.snackbarColor
        });
        return;
      }

      this.serviceRequestProcessing = true;
      let self = this;
      self.$store
        .dispatch(POST_DATA, {
          listName: `${API_SERVICE_REQUESTS}`,
          data: this.newRequest
        })
        .then(response => {
          if (response.status >= 200 && response.status <= 204) {
            self.snackbarContent =
              "Service Request has been created successfully";
            self.snackbarColor = "green";

            self.$router.push({
              name: "CustomerRequests",
              params: { customerId: self.customerId }
            });
          } else {
            self.snackbarContent = response.data;
            self.snackbarColor = "red";
          }
          self.$snackbar.showMessage({
            content: self.snackbarContent,
            color: self.snackbarColor
          });
          self.serviceRequestProcessing = false;
        });

      // this.saveCustomer();
    },
    async setInternalBreadcrumbs() {
      this.breadcrumbs = [
        {
          title: this.communityInfo.name,
          route: "/customers/" + this.customerId
        },
        {
          title: "Service Requests",
          route: "/customers/" + this.customerId + "/requests"
        },
        { title: "New Service Request" }
      ];
    },
    createBreadcrumb() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Customers", route: "/customers" },
        {
          title: this.communityInfo.name,
          route: "/customers/" + this.customerId
        },
        {
          title: "Service Requests",
          route: "/customers/" + this.customerId + "/requests"
        },
        { title: "New Service Request" }
      ]);
    },

    async getEmployees() {
      this.loadingCommunityUsers = true;
      await this.$store
        .dispatch(GET_QUERY, {
          listName: `${API_CUSTOMERS}/${this.customerId}/users`
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            this.communityUsers = response.data.$values;
          } else {
            this.$snackbar.showMessage({
              content:
                response.data ||
                "Cannot receive a list of users. Something went wrong.",
              color: "red"
            });
          }
          this.loadingCommunityUsers = false;
        });
    },
    // async getRecipients() {
    //   this.loadingYnTeamItems = true;
    //   // let comunityItems = dataModels.communityDetails;
    //   await this.$store
    //     .dispatch(GET_QUERY, {
    //       listName: API_EMPLOYEES
    //     })
    //     .then(response => {
    //       if (response.status >= 200 || response.status <= 204) {
    //         this.ynTeamItems = response.data;
    //         this.ynTeamItems.push(this.communityInfo.propertyManager);
    //       } else {
    //         this.$snackbar.showMessage({
    //           content: response.data || "Something went wrong!",
    //           color: "red"
    //         });
    //       }
    //       this.loadingYnTeamItems = false;
    //     });
    // },
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
    },
    onMultipleAddFile(files) {
      for (var i = 1; i < files.length; i++) {
        let max = Math.max(...this.attachmentsObj.map(i => i.id));
        this.attachmentsObj.push({
          id: (max += 1),
          url: files[i].fileUrl,
          fileId: files[i].id
        });
      }
      let max = Math.max(...this.attachmentsObj.map(i => i.id));
      this.attachmentsObj.push({ id: (max += 1), url: null });
      this.$nextTick(() => files.forEach(f => this.onChange(f)));
    },
    onChange(file) {
      if (file.fileUrl) {
        var found = this.attachmentsObj.find(f => f.url == file.fileUrl);
        found.fileId = file.id;
      }
    },
    onDeleteFile(fileData) {
      let index = this.attachmentsObj.findIndex(f => f.id === fileData.index);
      this.attachmentsObj.splice(index, 1);
    },
    formatDate(date) {
      return dateFormatter.formatEditDate(date);
    },
    formatDateUs(date) {
      // return moment(date).format("MM/DD/YYYY");
      return dateFormatter.formatDateUs(date);
    },
    capitalizeFirstLetter(string) {
      return stringFormatter.capitalizeFirstLetter(string);
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    }
  },
  computed: {
    accept() {
      return "*/*";
    },
    extensions() {
      return "gif,jpg,jpeg,png,webp,mp4,mov,avi,mkv,mpg,vob,wmv,m4v,asf,srt,pdf,doc,docx";
    }
  }
};
</script>
